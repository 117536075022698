import React from 'react';

export default class EquipeItem extends React.Component{


    constructor(props) {
        super(props);
        this.state = {deps:[], Mymodal: false}
    }


    render(){

        return(

                        <div className="col-md-4 col-lg-4">
                            <div className="servico-item mx-auto">
                                {this.props.children}
                                <h3 className="text-secondary m-3 p-1">{this.props.name}</h3>
                                <h5 className="text-secondary m-3 p-1">{this.props.occupation}</h5>
                            </div>
                        </div>

        );
    }
}