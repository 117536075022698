import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default class SlidePainel extends React.Component {


    constructor()
    {
        super();
        this.state = {
          };
    }

    render() {

        return (

                <>


                <section className="page-section portfolio" id="painel">
                    <div className="container">

                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Gateways</h2>

                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                        <i className="fa fa-star"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                        <ImageGallery items={this.props.images} autoPlay={true} />
                        </div>
                    </div>

                    </div>

                </section>

                </>

        );
    }
}