import React, {Component} from 'react';
import {Modal, Button } from 'react-bootstrap';

export default class Mymodal extends Component {
 
    
  render () {
    return (
 
      <Modal
      {...this.props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <h2 className="portfolio-modal-title text-secondary mb-0">+ detalhes</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body2">
      <div className="row justify-content-center">
              <div className="col-lg-8">
              <h2 className="portfolio-modal-title text-secondary text-center mb-0">Plano {this.props.titulo}</h2>
                <div className="divider-custom">
                  <div className="divider-custom-line"></div>
                  <div className="divider-custom-icon">
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="divider-custom-line"></div>
                </div>
                <p className="mb-5">{this.props.text}</p>
              </div>
            </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer2">
        <Button variant="danger" onClick={this.props.onHide}>Fechar Janela</Button>
      </Modal.Footer>
    </Modal>
 
      );
  }
}
