import React, { Suspense } from 'react'
import { CircularProgress } from '@material-ui/core'
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import Copyright from './Copyright';

export default class App extends React.Component{

    constructor()
    {
        super();
        this.dados=[];
        this.state = {
            product_id: 0,
            product_name: '',
            product_image: '',
            product_video: '',
            product_full_name: '',
            product_call: '',
            product_price: '',
            product_link: '',
            video_call: '',            
            warranty: '',
            images: [],
            benefits: [],
            features: [],
          };
    }

    setPrincipal = (data) => {
        this.setState({ 
            product_id: data.product_id, 
            product_name: data.product_name, 
            product_image: data.product_image, 
            product_video: data.product_video, 
            product_full_name: data.product_full_name, 
            product_call: data.product_call, 
            product_price: data.product_price, 
            product_link: data.product_link, 
            video_call: data.video_call, 
            benefits: data.benefits, 
            features: data.features, 
            warranty: data.warranty 
        })
    }

    setImages = (images) => {
        let imgtemp = images.map((item, i) => {
            return (
                {
                    original: process.env.PUBLIC_URL + 'storage/slides/' + item.original,
                    thumbnail: process.env.PUBLIC_URL + 'storage/slides/' + item.thumbnail,
                }
            )

          });
  
        this.setState({ images: imgtemp })
     }    


    componentDidMount() {
        let url = process.env.PUBLIC_URL + "storage/json/lampada.json";
        fetch(url)
            .then((response) => response.json()
                .then((data) => {
                    if(response.ok) {

                        this.setPrincipal (data);
                        this.setImages (data.images);

                    }
                })
            ).catch(
            error => console.log(error)
            );
    }

    render(){
        return(
            <div>
            <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-vh-100"> <CircularProgress/> </div>}>
                <Header product_id={this.state.product_id} product_name={this.state.product_name}/>
                <Main features={this.state.features} benefits={this.state.benefits} video_call={this.state.video_call} product_link={this.state.product_link} product_price={this.state.product_price} product_call={this.state.product_call} product_full_name={this.state.product_full_name} product_image={this.state.product_image} product_video={this.state.product_video} images={this.state.images}/>
                <Footer warranty={this.state.warranty}/>
                <Copyright/>
            </Suspense>
            </div>
        );
    }
}