import React from 'react';

export default class Card extends React.Component{
    constructor(props) {
        super(props);
        this.state = {deps:[], Mymodal: false}
    }
        //const [nome, setNome] = useState ('')

    render(){
        
        const cardStyle = {
        backgroundColor: this.props.color || '#F00',
        borderColor: this.props.color || '#F00'
        }

        const TitleStyle = {
        display: 'block'
        }
    

    return(

        <div className="Card slide-in-top" style={cardStyle}>
            <div className="Title" style={TitleStyle}>
                <i className={this.props.icon}></i> {this.props.titulo}    
            </div>
            <div className="Content">
                {this.props.children}
            </div>
        </div>

    );
}
}
