import React from 'react';
import EquipeItem from './EquipeItem';
import Avatar from "avataaars";

export default class Equipe extends React.Component {

    render(){

        return(
            <div>


                 {/* Portfolio Section */}
                 <section className="page-section text-white mb-0 servicos" id="equipe">
                    <div className="container">

                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Nossa Equipe</h2>

                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                        <i className="fa fa-star"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                        {/* Portfolio Grid Items */}
                        <div className="row">
                        <EquipeItem name="Eli" occupation="Design">

                        <Avatar
                            avatarStyle='Circle'
                            topType='NoHair'
                            accessoriesType='Round'
                            facialHairType='Blank'
                            clotheType='BlazerSweater'
                            eyeType='Default'
                            eyebrowType='Default'
                            mouthType='Default'
                            skinColor='Light'
                            />

                        </EquipeItem>

                        <EquipeItem name="Valeria" occupation="Suporte">

                        <Avatar
                            avatarStyle='Circle'
                            topType='LongHairBob'
                            accessoriesType='Blank'
                            hairColor='Brown'
                            facialHairType='Blank'
                            clotheType='ShirtCrewNeck'
                            clotheColor='Heather'
                            eyeType='Side'
                            eyebrowType='Default'
                            mouthType='Twinkle'
                            skinColor='Light'
                            />

                        </EquipeItem>

                        <EquipeItem name="Gabriel" occupation="Marketing">

                        <Avatar
                            avatarStyle='Circle'
                            topType='ShortHairDreads01'
                            accessoriesType='Prescription02'
                            hairColor='BrownDark'
                            facialHairType='BeardLight'
                            facialHairColor='Brown'
                            clotheType='ShirtVNeck'
                            clotheColor='Heather'
                            eyeType='Default'
                            eyebrowType='Default'
                            mouthType='Default'
                            skinColor='Light'
                            />

                        </EquipeItem>

                         </div>
                        {/* Final da linha */}

                    </div>
                </section>


            </div>
       );
    }
}