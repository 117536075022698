import React from 'react';

export default class Propaganda extends React.Component {

    render(){

        return (
          <>


            <section className="page-section portfolio" style={{ 'marginTop' : -105 + 'px' }} id="promotion">
                <div className="container">

                <h2 className="text-center text-secondary mb-0">Um sistema prático para a cobrança dos seus pedidos<br/> via whatsapp e pagamento on-line!</h2>

                </div>
            </section>

          </>
        );
      }

}