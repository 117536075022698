import React from 'react';

export default class Footer extends React.Component {

    
  constructor()
  {
      super();
      this.state = {
        };
    
  }

    render(){

        const estilo = {
            background: 'url('+process.env.PUBLIC_URL+'storage/backgrounds/red.png)'
        }

        return(
            <div className="footer" style={ estilo }>


                <div className="container">
                <div className="row">

                    {/* Footer Location */}
                    <div className="col-lg-4 mb-5 mb-lg-0 text-center">
                    <h4 className="text-uppercase mb-4">Atendimento</h4>
                    <p className="lead mb-0">Atendemos em todo o Brasil.</p>
                    </div>

                    {/* Footer Social Icons */}
                    <div className="col-lg-4 mb-5 mb-lg-0 text-center">
                    <h4 className="text-uppercase mb-4">Gateways</h4>
                    <button className="btn btn-social mx-3">
                        <img alt="" className="img-fluid" style={{ maxWidth : '200%' }} src={process.env.PUBLIC_URL+'storage/img/logomp.png'} />
                    </button>
                    <button className="btn btn-social mx-3">
                        <img alt="" className="img-fluid" style={{ maxWidth : '200%' }} src={process.env.PUBLIC_URL+'storage/img/logops.png'} />
                    </button>
                    </div>

                    {/* Footer About Text */}
                    <div className="col-lg-4 text-center">
                    <h4 className="text-uppercase mb-4">Whatsapp</h4>
                    <p className="lead mb-0"><a href="https://api.whatsapp.com/send?phone=5514997241400&text=Olá gostaria de informações sobre"  rel="noopener noreferrer" target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i> +55 (14) 997241400</a></p>
                    </div>

                </div>
                </div>


            </div>
        );
    }
}