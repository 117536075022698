import React from 'react';
import Mymodal from '../Mymodal';

export default class PlanosItem extends React.Component{


    constructor(props) {
        super(props);
        this.state = {deps:[], Mymodal: false}
    }


    render(){

        //const {deps} = this.state;
        let addModalClose =() => this.setState({addModalShow: false}) 

        return(

                        <div className="col-md-4 col-lg-4">
                            <h3 className="text-center text-primary"><b>Plano {this.props.title}</b></h3>
                            <h5 className="text-center text-danger"><b>{this.props.recomendado}</b></h5>
                            <div className="servico-item mx-auto" data-toggle="modal" onClick = {()=> this.setState({addModalShow:true})} >
                                <div className="servico-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className="servico-item-caption-content text-center text-white">
                                        <i className="fa fa-plus fa-3x"></i>
                                    </div>
                                </div>
                                <div style={{ padding : '15px', backgroundColor : 'indigo'}}>{this.props.minitext}</div>
                                <div style={{ padding : '15px', backgroundColor : 'indigo',  textDecoration: 'line-through'}}>{this.props.valuecut}</div>
                                <div style={{ padding : '15px', backgroundColor : 'indigo'}}>Apenas: {this.props.value} por marmita.</div>
                            </div>
                            <div style={{ marginBottom : '30px', height: '120px', borderRadius: '15px', zIndex : '-10', marginTop: '-65px', color: 'black', padding : '15px', backgroundColor : 'lightgray'}}>
                                    <br/><br/>
                                    <button className="btn btn-primary btn-block">Contratar</button>
                                </div>
                            <Mymodal show={this.state.addModalShow} imagem = {this.props.image} link = {this.props.link} titulo = {this.props.alt} text = {this.props.text} onHide = {addModalClose}/>
                        </div>

        );
    }
}