import React from 'react';
import PlanosItem from './PlanosItem';

export default class Planos extends React.Component {

    render(){

        return(
            <div>


                 {/* Portfolio Section */}
                 <section className="page-section text-white mb-0 servicos" id="servicos">
                    <div className="container">

                    <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Planos</h2>

                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                        <i className="fa fa-star"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                        {/* Portfolio Grid Items */}
                        <div className="row" style={{ maxWidth: '90%', margin: '0 auto' }}>
                        <PlanosItem image={process.env.PUBLIC_URL + "storage/img/criacaodesites.jpg"} alt="Iniciante" title="Iniciante" valuecut="0,25 centavos" value="0,15 centavos" link="https://brazilcats.com/tuttibom/" minitext="Plano ideal para pequenas empresas que estão iniciando na internet." text="Nesse plano você vai encontrar um sistema de pagamento integrado no formato mercado pago, é preciso ter uma conta no mercado pago. Você terá uma área administrativa para visualizar os pedidos dos seus clientes e enviar a marmita. Além de tudo isso você é informado por mensagem instântanea pelo painel administrativo para poder realizar o envio do produto já pago."/>
                        <PlanosItem image={process.env.PUBLIC_URL + "storage/img/lojasvirtuais.jpg"} alt="Profissional" title="Profissional" valuecut="0,50 centavos" value="0,25 centavos" link="https://eletrobackup.com.br/c/lancamentos" recomendado="Recomendado" minitext="Plano perfeito para pequenas e medias empresas que precisam cobrar seus clientes de forma fácil e intuitiva." text="O plano profissional traz todas as características do plano iniciante, no entanto você pode utilizar uma conta do pagseguro caso não utilize o mercado pago. Além da área administrativa, você recebe a notificação do pagamento da marmita atráves do whatsapp o que facilita ao seu vendedor visualizar os pedidos pagos sem precisar ir até a área administraiva."/>
                        <PlanosItem image={process.env.PUBLIC_URL + "storage/img/lojasvirtuais.jpg"} alt="Enterprise" title="Enterprise" valuecut="2,00 real" value="1,00 real" link="https://eletrobackup.com.br/c/lancamentos" minitext="Plano criado para grandes empresas com recursos de integração para aplicativos comerciais próprios." text="O plano enterprise traz todos os recursos mencionados nos outros planos e torna a experiência da empresa ainda melhor, pois se comunica internamente com o seu sistema comercial e atualiza os pratos automaticamente no painel administrativo."/>
                         </div>
                        {/* Final da linha */}

                    </div>
                </section>


            </div>
       );
    }
}