import React from 'react';
import Card from '../Card';

export default class Vantagens extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }


    render(){

        const renderBenefits = this.props.benefits.map((item, i) => {
            //return <li key={index}>{todo}</li>;
            return (
            <Card key={i} icon='fa fa-fw fa-star' titulo={item.title} image='' color="#499939">
                <p>{item.description}</p>            
            </Card>)
          });
  
        return(
            <>
                 {/* Portfolio Section */}
                 <section className="page-section text-white mb-0 p-5 vantagens" style={{ background: '#2b5273'}} id="vantagens">
                    <div className="container">

                    {/* About Section Heading */}
                    <h2 className="page-section-heading text-center text-uppercase pt-5 text-white">Benefícios</h2>

                    {/* Icon Divider */}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                        <i className="fa fa-star"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                        <div className="row">
                            <div className="Cards">
                            {renderBenefits}
                            </div>
                        </div>
                    </div>
                </section>
                <img alt="" src={process.env.PUBLIC_URL+'storage/img/bottom3.svg'} className="svgblue"/>

            </>
       );
    }
}