import React from 'react';
import AboutStore from "./main/AboutStore";
import SlidePainel from "./main/SlidePainel";
import ScrollToTop from "./Gotop";
import Planos from "./main/Planos";
import Equipe from "./main/Equipe";
import Caracteristicas from "./main/Caracteristicas";
import Video from "./main/VideoEspecial";
import Contact from "./main/Contact";
import Vantagens from "./main/Vantagens";
import Propaganda from "./main/Propaganda";

export default class Main extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }

    render(){
        return(
            <div className="main" id="home">
                <Video video_call={this.props.video_call} product_image={this.props.product_image} product_video={this.props.product_video}/>
                <AboutStore product_image={this.props.product_image} product_full_name={this.props.product_full_name}/>
                <Propaganda/>
                <Planos/>
                <Caracteristicas features={this.props.features}/>
                <SlidePainel images={this.props.images}/>
                <Equipe/>
                <Vantagens benefits={this.props.benefits}/>                
                <Contact/>
                <ScrollToTop/>
            </div>
        );
    }
}