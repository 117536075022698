import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ButtonMob from './ButtonMob';

export default class Nav extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }

    /* Fará a função de toggle */
    clickMe(scopo)
    {
        console.log(scopo);
        //var este = scopo;
        //este.classList.add("active");
    }

    render(){
        return(
            <div id="page-top">
                
                 {/* Navigation */}
                <nav className="navbar navbar-expand-lg text-uppercase fixed-top mainNav" id="mainNav">
                    <div className="container">
                    <AnchorLink className="navbar-brand js-scroll-trigger" offset="120" href="#page-top">
                    <img className="img-fluid" src={process.env.PUBLIC_URL+"storage/img/comendus.svg"} style={{ width: '100%', maxWidth: '250px', height: 'auto'}} alt=""/>
                    </AnchorLink>
                    <ButtonMob/>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item mx-0 mx-lg-1">
                            <AnchorLink className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" offset="50" href="#servicos">Planos</AnchorLink>
                        </li>
                        <li className="nav-item mx-0 mx-lg-1">
                            <AnchorLink className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" offset="50" href="#caracteristicas">Características</AnchorLink>
                        </li>
                        <li className="nav-item mx-0 mx-lg-1">
                            <AnchorLink className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" offset="50" href="#painel">Gateways</AnchorLink>
                        </li>
                        <li className="nav-item mx-0 mx-lg-1">
                            <AnchorLink className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" offset="50" href="#contact">Contato</AnchorLink>
                        </li>
                        </ul>
                    </div>
                    </div>
                </nav>

            </div>
            );
    }
}