import React from 'react';
import Nav from "./header/Nav";

export default class Header extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }

    render(){
        return(
            <div id="header">
                <Nav product_id={this.props.product_id} product_name={this.props.product_name} />
            </div>
        );
    }
}