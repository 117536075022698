import React from 'react';

export default class AboutStore extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }

    render(){

        return(
            <>
                <section className="page-section gradient-custom text-white mb-0 about" id="about">
                    <div className="container">

                    {/* Icon Divider */}
                    <div className="divider-custom divider-light">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon">
                        <i className="fa fa-star"></i>
                        </div>
                        <div className="divider-custom-line"></div>
                    </div>

                    {/* About Section Content */}
                    <div className="row">
                        <div className="col-lg-12 ml-auto">
                        <h1 className="text-center">Seja muito bem-vindo(a) ao Comendus!</h1>
                        </div>
                    </div>

                    </div>
                </section>
                <img alt="" src={process.env.PUBLIC_URL+'storage/img/bottom2.svg'} />
            </>
       );
    }
}