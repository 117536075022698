import React from 'react';
import Card from '../Card';

export default class Caracteristicas extends React.Component {

    constructor()
    {
        super();
        this.state = {
          };
    }

    render(){

        const renderFeatures = this.props.features.map((item, i) => {
            //return <li key={index}>{todo}</li>;
            return (
            <Card key={i} icon='fa fa-fw fa-star' titulo="" image='' color="#499939">
                <p>{item}</p>            
            </Card>)
          });
    
      return(
        <>

            <section className="page-section text-white mb-0 caracteristicas" id="caracteristicas">
                <div className="container">

                {/* About Section Heading */}
                <h2 className="page-section-heading text-center text-uppercase text-white">Características</h2>

                {/* Icon Divider */}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon">
                    <i className="fa fa-star"></i>
                    </div>
                    <div className="divider-custom-line"></div>
                </div>

                {/* About Section Content */}
                <div className="row">  
  
                    <div className="Cards">
                        {renderFeatures}
                    </div>

                </div>

                </div>
            </section>
            <img alt="" src={process.env.PUBLIC_URL+'storage/img/bottom1.svg'} />
        </>
   );
}
}